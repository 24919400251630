$(function () {

    var isAnimating = false;

    // open first accordion items on load. can't hard code this since its in a cms, der.
    $('.single-accord:first-of-type .b').addClass('current');
    $('.single-accord:first-of-type .h i').addClass('rotate-icon');

    $('.single-accord .h').on('click', function (e) {
        // limiting scope of interaction to just one carousel when more than one carousels are on the page.
        var $curAccord = $(this).parent().parent();
        if (!isAnimating) {
            isAnimating = true;
            if ($(this).next().hasClass('current')) {
                $curAccord.find('.single-accord .h i').removeClass('rotate-icon');
                $(this).next().removeClass('current').slideUp(function () {
                    isAnimating = false;
                });
            } else {
                $curAccord.find('.single-accord .b').slideUp().removeClass('current');
                $curAccord.find('.single-accord .h i').removeClass('rotate-icon');
                $(this).next().addClass('current').slideToggle(function () {
                    isAnimating = false;
                });
                $(this).find('i').addClass('rotate-icon');
            }
        }
    });

    // this automates the height of the previous modules bottom decor and adds the correct ammount of padding in order for modules to not be covered.
    // to have module not add padding to its next() sibling, simply add data-ignore-padding="true" to your module
    var $modules = $('main').children();


    // extra padding top to add to modules to give content breathing room from decoation imgs
    var additionalPadding = 5;

    setTimeout(function () {
        for (var i = 0; i < $modules.length; i++) {
            var $paddingTarget = $($modules[i]);
            var $decorTarget = $($modules[i]).prev();
            var $decoration;
            var ratio;
            if ($decorTarget.length > 0) {

                if ($paddingTarget.attr('data-custom-pad') || $paddingTarget.attr('data-no-pad')) {

                } else {

                    if (!($decorTarget.attr('data-contain-decor')) && !($paddingTarget.attr('data-contain-decor'))) {
                        $decoration = $decorTarget.find('.decoration');
                        // ratio = Math.ceil(($decoration.height()/$decoration.width())*100);
                        ratio = ($decoration.height() / $decoration.width()) * 100;
                        $paddingTarget.css('padding-top', (ratio + additionalPadding) + '%');

                    } else if ($decorTarget.hasClass('small-banner')) {

                        $paddingTarget.css('padding-top', '0%');


                    } else if ($paddingTarget.hasClass('pitch-overview')) {
                        $decorTarget.find('.decoration').addClass('pre-pitch-decor');
                    } else {

                        $decoration = $paddingTarget.find('.decoration');
                        ratio = Math.ceil(($decoration.height() / $decoration.width()) * 100);
                        $paddingTarget.css('padding-bottom', (ratio + additionalPadding) + '%');
                    }
                }

            }
        }
        $('body').addClass('show');
    }, 500);

    // Help Text toggle
    $('.help-text-icon').on('click', function (e) {
        e.stopPropagation();
		var $clicked = $(this);

		if (!($clicked.hasClass('current'))) {
			$('.help-text-icon').removeClass('current');
        	$('.help-text-container').hide();
			$clicked.addClass('current');	
			$clicked.find('.help-text-container').show();
		} else {
			$('.help-text-icon').removeClass('current');
        	$('.help-text-container').hide();
		}
        
    });

    $('.help-text-container').on('click', function (e) {
    	e.stopPropagation();
        $(this).hide();
        $('.help-text-icon').removeClass('current');
	    $('.help-text-container').hide();
    });

    $(window).on('click', function () {
        $('.help-text-icon').removeClass('current');
        $('.help-text-container').hide();
    });

    $('.mobile-nav-toggle').on('click', function () {
        $(this).toggleClass('active');
        $('.nav').toggleClass('mobile-active');
        $('body').toggleClass('scroll-lock');
    });

    $(window).on('resize', function () {
        $('.mobile-nav-toggle').removeClass('active');
        $('.nav').removeClass('mobile-active');
        $('body').removeClass('scroll-lock');
    });
});