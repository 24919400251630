document.addEventListener('DOMContentLoaded', function () {

	// TABLES BLOCK SCRIPTS
	// wraps scrollable tables on medium and smaller viewports
	function scrollWrap() {
		$('table').wrap('<div class="scroll-wrapper"></div>');
		$('.scroll-wrapper').before('<div class="scroll-header">Swipe to view more content</div>');
	}

	scrollWrap();

	// show or hide table scroll header based on if overflowing its parent
	function handleScrollResize() {
		$('table').each(function (index) {
			var table = $(this);
			var tableWidth = table.outerWidth();
			var parentWidth = table.parent().outerWidth();
			var scrollHeader = table.parent().prevAll('.scroll-header:first');

			if (tableWidth > parentWidth) {
				// element has overflow
				scrollHeader.show();
			} else {
				// element doesn't overflow
				scrollHeader.hide();
			}
		})
	}

	$(window).on("resize", function () {
		handleScrollResize();
	});

	$(document).ready(function () {
		handleScrollResize();
	});
});